import React, { useEffect, useRef } from "react";
// import bannerMoc from "../assets/images/mocs/banner-moc-2-1.png";
// import burgerAnimation from "../assets/lotties/building-molecules.json";
// import uLottie from "../components/Lottie";
import lottie from "lottie-web";
// import Lottie from 'react-lottie';
// import Lottie from "./Lottie";


const BannerTwo = () => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/images/lotties/building-molecules.json")
    });
  }, []);

  return (
    <section className="banner-two" id="banner">
      <span className="banner-two__shape-1"></span>
      <span className="banner-two__shape-2"></span>
      <span className="banner-two__shape-3"></span>
      <span className="banner-two__shape-4"></span>
      <div className="banner-two__moc">
        <div className="lottiebanner" ref={container}></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-8 col-sm-12">
            <div className="banner-two__content">
              <h3 className="banner-two__title">
                Design <br />
                Engineering 
                <br />
                <span>Development</span>
                <br />
              </h3>
              <p className="banner-two__text">
                We transform technology in molecules <br /> We develop
                innovative processes based on <br />{" "}
                <strong>engineering, science and design.</strong>
              </p>
              <a href="https://m.me/MoleculaLAB" className="banner-two__btn thm-btn ">
                <span>Contact our scientists</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerTwo;
