import React from "react";
import Layout from "../components/Layout";
import NavTwo from "../components/NavTwo";
import Footer from "../components/Footer";
import BannerTwo from "../components/BannerTwo";
import Features from "../components/Features";
import CallToAction from "../components/CallToAction";
import CallToActionTwo from "../components/CallToActionTwo";
import Brands from "../components/Brands";
import Counter from "../components/Counter";

import Faq from "../components/Faq";
import NewsHome from "../components/NewsHome";
import TeamPage from "../components/TeamPage";
// import Video from "../components/Video";


const HomeLab = () => (
  <Layout pageTitle="Molecula Lab - We are a specialized development laboratory in Software, Apps, Web, Design and Digital marketing ">
    <NavTwo />
    <BannerTwo />
    <Features />
    <CallToAction />
    <CallToActionTwo />
    <TeamPage />
    <Brands />
    <NewsHome />
    <Counter />
    <Footer />
  </Layout>
);

export default HomeLab;
