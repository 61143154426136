import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

const CallToActionTwo = () => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/images/lotties/coding.json"),
    });
  }, []);

  return (
    <section className="cta-two">
      <div className="container">
        <div
          className="lottiedev col-sm-12 img-fluid cta-two__moc xla"
          ref={container}
        ></div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="cta-two__content col-sm-12">
              <i className="cta-two__icon dimon-icon-data col-sm-12"></i>
              <div className="block-title text-left col-sm-12">
                <h2 className="block-title__title col-sm-12">
                  Product Ideation <span>+</span> <br /> Agile Development
                </h2>
              </div>
              <div className="cta-two__text">
                <p>
                  We develop software for their needs in the appropriate
                  programming language and cost-effective.
                </p>
                <p>
                  In our laboratories we create and develop innovative molecules
                  based on <b>Design Thinking and Agile methodologies.</b>
                </p>
                <p>
                  <b>Do you have a project in mind?</b><br/>Contact our scientific expert to develop your own software molecule
                </p>
              </div>
              <a href="https://m.me/MoleculaLAB" className="thm-btn">
                <span>Contact our scientists</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CallToActionTwo;
