import React from "react";

const Features = () => {
  return (
    <section className="service-one" id="solutions">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            We create and develop the best
            <br /> <span>Software Molecules</span>
          </h2>
        </div>
        <div className="row">
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-laptop"></i>
                <h3>
                  <a href="#none">
                    App + Web <br /> Development
                  </a>
                </h3>
                <p>We synthesize molecules <br/>in experiences</p>
                <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-presentation"></i>
                <h3>
                  <a href="#none">
                    e-Commerce <br /> Solutions
                  </a>
                </h3>
                <p>We react Molecules to build <br/>scalable online stores</p>
                <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-target"></i>
                <h3>
                  <a href="#none">
                    Digital Marketing <br />+ Innovation
                  </a>
                </h3>
                <p>We catalyze molecules <br/>in strategies</p>
                <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-visualization"></i>
                <h3>
                  <a href="#none">
                    Custom <br /> Development
                  </a>
                </h3>
                <p>We code custom <br/>molecules</p>
                <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Features;
